@import "./antd.css";
@import "./fonts.css";

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.a-canvas {
	object-fit: cover;
}

.antd-slider {
	.ant-slider-handle {
		border: 0;
		color: white;
	}
	.ant-slider-track {
		background-color: white;
		color: white;
	}
	.ant-slider-rail {
		background-color: white;
		color: white;
	}
	:focus {
		outline: none;
	  }
}
.ant-slider-vertical .ant-slider-mark {
	left: -6px;
	width: 28px;
}


iframe[src*="iframe-bundle.js"], body>iframe {
	display: none !important;
}

::-webkit-scrollbar {
	height: 12px;
	width: 5px;
	background: #393812;
}

::-webkit-scrollbar-thumb {
	background: #aaa;
	WebkitBorderRadius: 6px;
	border-radius: 6px;
}

::-webkit-scrollbar-corner {
	background: #000;
	WebkitBorderRadius: 6px;
	border-radius: 6px;
}

* {
	scrollbar-width: thin !important;
}
